"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesCreateCertificate = exports.CertificatesCreateCertificateOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.CertificatesCreateCertificateOperationId = 'CertificatesCreateCertificate';
/**
 * Create a new custom certificate
 *
 * @path /certificates
 * @generated
 */
exports.CertificatesCreateCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
