"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesDuplicateCertificate = exports.CertificatesDuplicateCertificateOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CertificatesDuplicateCertificateOperationId = 'CertificatesDuplicateCertificate';
/**
 * Duplicate an existing certificate template
 *
 * @path /certificates/:certificateId/duplicate
 * @generated
 */
exports.CertificatesDuplicateCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/:certificateId/duplicate',
    method: 'POST',
    definedStatusCodes: [204, 400, 401, 403, 404, 500],
});
