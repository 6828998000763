"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesListCertificates = exports.CertificatesListCertificatesOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.CertificatesListCertificatesOperationId = 'CertificatesListCertificates';
/**
 * List unarchived custom certificates
 *
 * @path /certificates
 * @generated
 */
exports.CertificatesListCertificates = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 500],
});
