"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesUpdateCertificate = exports.CertificatesUpdateCertificateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificatesUpdateCertificateOperationId = 'CertificatesUpdateCertificate';
/**
 * Update an existing certificate
 *
 * @path /certificates/:certificateId
 * @generated
 */
exports.CertificatesUpdateCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/:certificateId',
    method: 'PATCH',
    definedStatusCodes: [204, 400, 401, 403, 404, 500],
});
