"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCourseAvailableDates = exports.GetCourseAvailableDatesOperationId = void 0;
const ClientController_1 = require("../../../../../../../ClientController");
exports.GetCourseAvailableDatesOperationId = 'GetCourseAvailableDates';
/**
 * Gets the available days in a given time range for the course
 *
 * @path /courses/:educatorSlug/:courseSlug/availability/dates
 * @generated
 */
exports.GetCourseAvailableDates = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:educatorSlug/:courseSlug/availability/dates',
    method: 'GET',
    definedStatusCodes: [200],
});
