"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesArchiveCertificate = exports.CertificatesArchiveCertificateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificatesArchiveCertificateOperationId = 'CertificatesArchiveCertificate';
/**
 * Archive certificate
 *
 * @path /certificates/:certificateId
 * @generated
 */
exports.CertificatesArchiveCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/:certificateId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 403, 500],
});
