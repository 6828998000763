"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatesGetCertificate = exports.CertificatesGetCertificateOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CertificatesGetCertificateOperationId = 'CertificatesGetCertificate';
/**
 * Get certificate by id
 *
 * @path /certificates/:certificateId
 * @generated
 */
exports.CertificatesGetCertificate = (0, ClientController_1.ClientControllerFactory)({
    path: '/certificates/:certificateId',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 403, 404, 500],
});
